


a {
  text-decoration: none;
}

/*css for custom buttons in react-bootstrap */
.btn-custom
{
  background-color: #FF4337;
  color:white;
  box-shadow: none !important;
}
.styledLink
{
  color:white;
}
/*css for custom buttons for download forms mostly located in accordions in react-bootstrap */
.btn-formButtonCustom:hover
{
  background-color: #FF4337;
  
}
.btn-formButtonCustom
{
  border: solid;
  border-width: thin;
 
  
}
.formButtonLink
{
  color: black;
}
.formButtonLink:hover
{
  color: white;
}

.noVisibilityThing
{
  visibility: hidden;
}

.linkButton {
  background-color: #FF4337;
	
	border: none;
	padding: 01;
	font: inherit;
	outline: inherit;
  font-size: 28px;
}

.noItalics {
  font-style: normal; 
}

a:hover {
  text-decoration: none;
  color: rgb(1, 4, 151);
}

.linkButton:hover {
  background: none;
  text-decoration: none;
  color:#FF4337;
}

/* .hstextFont {
  font-family: "Century Schoolbook", Georgia, serif;
} */

#blueColor {
  color: #007bff;
}
/* --------------------------Navigation Bar-----------------------------*/
.navbar-brand {
  height: auto !important;
}

.nav-bar {
  padding: 0px !important;
}
/*this adjusts the h&s logo at the top of the page*/
.hsBrand {
  width: 80%;
  height: auto;
  padding-left: 10% !important;
  
  
} /*The following three adjust the logo depending on screen size to prevent cutting off images/text */
@media only screen and (max-width: 1550px) {
  .hsBrand {
    width: 70%;
    height: auto;
  }
}
@media only screen and (max-width: 1250px) {
  .hsBrand {
    width: 60%;
    height: auto;
  }
}
@media only screen and (max-width: 1050px) {
  .hsBrand {
    width: 55%;
    height: auto;
  }
}
.contactBrand{
  max-width: 90%;
}

@media (min-width: 1000px) {
  .navigationRight {
    padding-left: 5% !important;
  }
}

@media (max-width: 1200px) {
  h4 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 750px) {
  .hsBrand {
    padding-left: 2% !important;
  }
  .navbar-brand {
    max-width: 60%;
  }
}

.navbar-default .navbar-custom .navbar {
  background-color: white;
  border-color: white;
}

.navbar-custom .navbar-nav > li > a {
  color: #003CA6 !important;
  font-size: 16px;
  line-height: 250% !important;
}
@media (min-width: 1000px) {
.navbar-custom .navbar-nav > li > a {
  
  font-size: 8px;
  
}
}
.navMenuItem:hover {
  color:#003CA6;
}

.carousel-indicators li {
  background-color: black;
}
/* 
/*---------------------------Hero Carousel------------------------------*/
/* .heroCarouselImages{
  opacity: .50;
  background-size: cover;

} */
/* .carousel-item{
  height: 250px;
  text-align: center;
} */
/* .carousel{
max-width: 100%;
height: 250px;
width: 100%;

} */
/* .carousel-caption{
  font-size: 2.5vh;
} */

/* .carousel-inner{
  background-color: black;
  height: 250px;
} */
/* @media(max-width: 420px){
  .carousel-caption{
    font-size: 3vh;
    top: 20% !important;
  
  }
} */
/*--------Body Container-------*/
.bodyContainer {
  width: 100% !important;
  /* padding-bottom: 6.5rem; */
}

#bodycontainer {
  min-height: 100vh;
  position: relative;
}

/*---------------------------Landing Banner------------------------*/
.landingBanner {
  background-color: #003CA6;
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Arial, Helvetica", sans-serif;
  text-align: center;
  font-size: 2.5vw;
  
}

.landingPosition {
  position: relative;
  overflow: hidden;
}

.styled-player {
  margin: 0 auto;
  margin-top: 40px;
}

.centerText {
  text-align: center;
  
}
/* Edited so that these are flat numbers, however the padding-top accounts for getting overlapped by the navigation banner  -jsg 5/4/2022*/
#landingBannerFirst {
  padding-top: 210px;
  padding-bottom: 40px;
}

#landingBannerServices {
  font-size: 2vw !important;
}
@media (min-width: 1000px) {

#fieldServicelandingBannerServices {
padding-top: 10%;
}
}
@media (max-width: 420px) {
  #landingBannerFirst {
    padding-top: 35%;
  }
  .landingBanner {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 5vw !important;
  }
  #landingBannerServices {
    font-size: 5vw !important;
    padding-top: 100px;
  }
  #fieldServicelandingBannerServices {
    font-size: 5vw !important;
    padding-top: 100px;
  }

}

/*--------------------------Landing Cards Mobile--------------------------*/

#LandingCardsRow {
  padding-top: 100px;
  padding-bottom: 100px;
}

.LandingCardsCol {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.thumbnail {
  max-width: 80%;
  margin: 0 auto;
  border: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (min-width: 1200px) {
  .thumbnail {
    height: 500px;
  }
}
@media (max-width: 1200px) {
  .thumbnail {
    height: 500px;
    max-width: 100%;
  }
}
@media (max-width: 1000px) {
  .thumbnail {
    height: 650px;
    max-width: 60%;
  }
  .LandingCardsCol {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 800px) {
  .thumbnail {
    height: 600px;
    max-width: 80%;
  }
}
@media (max-width: 600px) {
  .thumbnail {
    height: 500px;
    max-width: 90%;
  }
  #LandingCardsRow {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
/*-----------------------------Landing Cards Desktop --------------------*/
.containingDivLandingD {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.landingCardDImg {
  display: block;
  width: 100%;
  height: auto;
}
.cardOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(15, 15, 15, 0.8);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.containingDivLandingD:hover .cardOverlay {
  height: 100%;
}
.landingCardDText {
  color: white;
  font-size: 1.2vw;
  position: absolute;
  top: 40%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
}

.up {
  position: absolute;
  bottom: 10%;
  right: 10%;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.up:hover {
  display: none;
}
i {
  border: solid white;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 10px;
}

/*-----------------------Contact Form -------------------------*/

#errorText {
  color: red;
}
.contactMessage {
  font-size: 1.5vw;
  
  line-height: 40px;
}
.HSLogoText {
 
  color: rgb(1, 4, 151);
}
/* .HSLogoTextBlack {
  font-family: "Century Schoolbook", Georgia, serif;
} */

.TechnicalServicesText {
  
  color: rgb(1, 4, 151);
}

.contactButton {
  background-color: #003CA6;
  color: white;
  float: right;
}

#contact-form {
  width: 100%;
  float: right;
}
@media (max-width: 500px) {
  #contact-form {
    padding-left: 15px;
  }
}

#contact-info {
  font-size: 16px;
}

#contactRow {
  /* padding-top: 100px;
  padding-bottom: 100px; */
  margin: 0 auto;
}
.FieldEngineerHeadshot {
  max-width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}

.valuesText {
  height: 90%;
  min-height: 200px;
}
.fieldservicesheaderp {
width: 75%;
margin: 0 auto;
}
/* #contactRowLg {
  padding: 50px;
} */

#contactRowLg {
  padding: 50px;
  background-color: #003CA6;
}

/* #contactRowSm {
  padding-right: 50px;
} */

@media (max-width: 600px) {
  #contactRowSm {
    padding-right: 0px;
  }
  #contactRowLg {
    padding-left: 10px;
  }
}

@media (max-width: 800px) {
  #contactRow {
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0 auto;
    width: 90%;
  }
}

/*----------------------------Footer------------------------>*/

div #copyright {
  font-size: 1vw;
  margin: 2px;
 justify-content: flex-end;
}

@media (max-width: 500px) {
  #copyright {
    font-size: 2.8vw;
  }
  .footerNavLink {
    font-size: 2.8vw !important;
  }
}

/*-------------------------------------Accordions----------------------------*/

#fieldServiceEngineeringJumbo {
  width: 100%;
  margin: 0 auto;
  padding-top: 0%;
}
.accordionHeader{
  padding: 0%;
  color: #212529;
  font-size: 28px;
  
}

.accordionButton{
border-style: none;
outline-style: none;
background-color: #f7f7f7 ;
width: 100%;
padding: 1em;
box-sizing: border-box;
}
/*-------------------------------------Services Page -----------------------------*/

#serviceTabContainer {
  padding-top: 75px;
  padding-bottom: 5%;
}
#servicesImg {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (min-width: 800px) {
  #smallTabImg {
    margin-left: 10%;
  }
}

#servicesHeaderText {
  font-size: 1.5vw;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 90%;
}
@media (max-width: 500px) {
  #servicesHeaderText {
    font-size: 5vw;
    width: 90%;
    margin: 0 auto;
  }

  .contactMessage {
    font-size: 4vw;
  }
  .productshead {
    font-size: 6vw;
  }
  #productsTabContainer {
    width: 100%;
  }
  .nav-tabs > li > a {
    font-size: 2.5vw;
  }
}
.jumbotron {
  background-color: white !important;
}

#serviceTabContainer {
  width: 80%;
  margin: 0 auto;
}

.nav-tabs > li > a {
  font-size: 1.8vw;
}

.tabCol {
  margin-top: 50px;
}

.tabText {
  font-size: 1.5vw;
  margin: 0 auto;
  padding-top: 25px;
}

.tabImg {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shortImg {
  margin-top: 10% !important;
}

@media (max-width: 500px) {
  .shortImg {
    margin-top: 0% !important;
  }
  .tabText {
    font-size: 5vw !important;
  }
}

@media (max-width: 1000px) {
  .tabText {
    font-size: 5vw !important;
  }
}
/*-----------------------Products----------------------*/
.jumbotron {
  padding-top: 8%;
}

.productshead .HSLogoText {
  color: white;
}
.white {
  color: white;
  cursor: pointer;
}
.learnMoreLink {
  color: white !important;
}
.nonWhite {
  color: rgb(33, 37, 41) !important;
}

.productshead {
  font-size: 3vw;
}

.nav-tabs {
  font-size: 1.5rem;
}

@media (max-width: 500px) {
  .nav-tabs {
    font-size: 1rem;
  }
  .productshead {
    font-size: 8vw;
    text-align: center;
  }
  #spacingDiv {
    text-align: center;
  }
}

.productsTabContainer {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 75px;
  padding-top: 3rem;
}

.valve {
  margin-left: 25%;
}

#calLab2 {
  margin-left: 10%;
}

@media (max-width: 500px) {
  .valve {
    margin-left: 5%;
  }
}

#spacingDiv {
  padding-left: 10px;
  padding-right: 10px;
}

.displayItem {
  display: true;
}

.hideItem {
  display: none;
}

#displayItemb {
  display: true;
}

#hideItemb {
  display: none;
}

/* ------------------------Tech support------------------- */

.stretch {
  margin: auto;
  display: block;

  max-width: 90%;
  box-shadow: 0 4px 8px 0 rgba(10, 52, 219, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#techsupportJumbotron {
  margin: 0 auto;
}

.techsupportText {
  padding-top: 50px;
  width: 75%;
  font-size: 20px;
  margin: 0 auto;
}

#techSupportTabsDiv {
  width: 80%;
  margin: 0 auto;
}

#aboutHeaderText {
  width: 75%;
  text-align: center;
  margin: 0 auto;
}

#companyImg {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-shadow: 0 4px 8px 0 rgba(10, 52, 219, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.historyText {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 1.2rem;
  color: #003CA6;
}

.landingCardsMobile {
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.who-we-are-tabs {
  width: 75%;
  margin: 0 auto;
  border-radius: 0 px !important;
}
@media (max-width: 500px) {
  .who-we-are-tabs {
    width: 90%;
  }
}
.nav-pills .nav-link {
  border-radius: 0 px !important;
}

.who-we-are-tabs .active {
  background-color: white !important;
  color: #003CA6 !important;
}
.who-we-are-tabs .nav-link {
  background-color: #003CA6;
  color: white;
}

.who-we-are-tabs .nav-item {
  color: white !important;
  font-size: 30px;
}

.who-we-are-tabs .nav-item.active {
  color: #003CA6 !important;
}

.bioRows {
  height: 900px;
  color: #003CA6;
  margin-bottom: 20px;
  margin-top: 20px;
}
.bioCarousel .carousel-inner {
  background-color: white !important;
  max-height: 1200px;
}
.bioCarousel {
  background-color: white !important;
  max-height: 1200px !important;
}

.bioCard {
  border: none;
  width: 75%;
  margin: 0 auto;
  color: #003CA6;
}

.bioImgs {
  max-width: 30%;
  margin-bottom: 20px;
}

@media (max-width: 500px) {
  .bioImgs {
    max-width: 70%;
  }
  .jumbotron {
    padding-top: 30%;
  }
  
  #jumbotronNoImage {
    padding-top: 10%;
  }
  .bioCard {
    width: 100%;
  }
  .bioCarousel {
    height: 1500px !important;
    max-height: 1500px !important;
  }
  .bioCarousel .carousel-inner {
    height: 1500px !important;
    max-height: 1500px !important;
  }
  .bioCard .card-body {
    font-size: 15px;
  }
  .who-we-are-tabs .tab-content {
    height: 1300px;
  }
  .who-we-are-tabs .tab-pane {
    width: 90%;
  }
  .bioRows {
    height: 1300px;
  }
  /* .carousel-control-next,
  .carousel-control-prev {
    width: 10%;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 15px !important;
    height: 15px !important;
  }
  .carousel-inner {
    width: 90%;
    margin: 0 auto;
  } */
}

.bioCard .card-body {
  font-size: 18px;
}

/* .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23337ab7' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  width: 50px;
  height: 50px;
}
/*  */
/* .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23337ab7' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  width: 50px;
  height: 50px;
} */

.valuesContainer .alert-light { 
  color: #003CA6 !important;
}

.contactJumbotron {
  min-height: 1000px;
}

@media (min-width: 800px) {
  .calImages {
    padding-top: 10%;
  }
}

#PHBufferImg {
  max-height: 300px;
  margin-left: 30%;
}

#techSupportHeaderRow {
  padding-top: 10%;
  padding-bottom: 8%;
}

.techSupportImages {
  align-content: center;
}

#heroimage {
  width: 100%;
  background-size: cover;
  max-height: 225px;
}

@media (max-width: 800px) {
  .techSupportImages {
    margin: 10px;
  }
  #heroimage {
    max-height: 200px;
  }
}

#mission {
  text-align: center;
  
}

.valuesContainer p {
  color: black;
}

#quote {
  float: right;
}

.contractManufacturingText {
  width: 75%;
  text-align: center;
  margin: 0 auto;
  font-size: 1.5vw;
}

.contractManufacturingImg {
  max-width: 90%;
}

#careers {
  font-size: 20px;
  text-align: center;
  width: 75%;
  margin: 0 auto;
}

#contractManufacturing {
  font-size: 20px;
}

#careersHeader {
  text-align: center;
}

@media (max-width: 500px) {
  #careers {
    width: 90%;
  }
}

#flukeDiv {
  padding-bottom: 50px;
}

.fluke {
  display: block;
  margin: auto;
}

.alertText {
  font-size: 22px;
}







/* 

breaker for modal styling


*/

.iIDDUy {
  background: rgb(255, 255, 255);
  border-radius: 0.3125rem;
  max-width: 35.25rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 2.75rem;
  position: relative;
}

.ebeRtN {
  align-items: flex-start;
  display: flex;
}

.iHsWXX {
  font-size: 0.875rem;
  padding-left: 0.5rem;
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(67, 77, 93);
}

.jMsntP {
  color: rgb(221, 54, 42);
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 0.125rem;
}

.eKOoKL {
  flex-shrink: 0;
  height: 1rem;
  position: relative;
  top: 0.08rem;
  width: 1rem;
}

.dEVaGV {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(93, 93, 101);
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.jmmzhM {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(9, 9, 9);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.cNEVYQ {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(7, 7, 7);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.hWfcgx {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(6, 6, 6);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.bHEliL {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(5, 5, 5);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.hvZbob {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(3, 3, 3);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.gzsoiH {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(2, 2, 2);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.golxPc {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(1, 1, 1);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.hUEbPw {
  font-family: Helvetica, Arial, sans-serif;
  color: rgb(0, 0, 0);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.iFTUZ {
  color: rgb(221, 54, 42);
}

.iMsgpL {
  appearance: none;
  background-color: rgb(255, 255, 255);
  border: 0px;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  box-shadow: rgba(142, 154, 173, 0.1) 0px 2px 0px 0px inset, rgb(210, 215, 223) 0px 0px 0px 1px inset, rgb(255, 255, 255) 0px 1px 0px 0px;
  color: rgb(67, 77, 93);
  font-size: 0.875rem;
  line-height: 1.5;
  min-height: 2.8125rem;
  outline: 0px;
  padding: 0.75rem 1rem;
  transition: box-shadow 0.2s ease 0s;
  width: 100%;
}

.iMsgpL:focus {
  box-shadow: transparent 0px 0px 0px 0px inset, rgba(0, 0, 0, 0.2) 0px 0px 0px 1px inset, rgba(80, 156, 246, 0.25) 0px 0px 0px 2px;
}

.bMslyb {
  margin-bottom: 1.5rem;
}

.liOVdz {
  margin-bottom: 1rem;
}

.dLkilY {
  margin-left: auto;
  margin-right: auto;
  max-width: 29.125rem;
  padding-bottom: 3.125rem;
}

.wSZJN {
  font-size: 3rem;
  text-align: center;
  letter-spacing: -1px;
  line-height: 1.17;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-family: Lato, sans-serif;
  color: rgb(0, 0, 0);
}

.dckdRg {
  font-size: 48px;
  text-align: center;
  letter-spacing: -1px;
  line-height: 1.17;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-family: Lato, sans-serif;
  color: rgb(4, 101, 254);
}

.urQia {
  border-bottom: 1px solid rgb(210, 215, 223);
  margin-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.gnexaL {
  margin-bottom: 0.25rem;
}

.jHkwuK {
  background-color: rgb(123, 177, 61);
  border: none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}

.jHkwuK:hover {
  cursor: pointer;
}

.hVKtlH {
  background-color: rgb(123, 177, 3);
  border: none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}

.hVKtlH:hover {
  cursor: pointer;
}

.CATVO {
  background-color: rgb(119, 187, 187);
  border: none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}

.CATVO:hover {
  cursor: pointer;
}

.dhtyIY {
  background-color: rgb(0, 68, 102);
  border: none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}

.dhtyIY:hover {
  cursor: pointer;
}

.dMfjsD {
  background-color: rgb(4, 101, 15);
  border: none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}

.dMfjsD:hover {
  cursor: pointer;
}

.fwPvIT {
  background-color: rgb(4, 101, 254);
  border: none;
  border-radius: 3px;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  outline: 0px;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: background-color 0.1s ease-in 0s, box-shadow 0.1s ease-in 0s;
}

.fwPvIT:hover {
  cursor: pointer;
}



blockquote {
  font-family: Georgia, serif;
  font-size: 1em;
  position: relative;
  margin: 0.5em;
  padding: 0.5em 2em 0.5em 2em;
}
/* Thanks: http://callmenick.com/post/styling-blockquotes-with-css-pseudo-classes */
/* blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 6em;
  line-height: 1;
  top: 0;
  left: 0;
  content: "\201C";
} */
/* blockquote:after {
  font-family: Georgia, serif;
  position: absolute;
 
  font-size:6em;
  line-height: 1;
  right:0;
  bottom:-0.5em;
  content: "\201D";
} */
blockquote footer {
  font-family: Georgia, serif;
  padding: 0 2em 0 0;
  text-align:right;
}
blockquote cite:before {
  font-family: Georgia, serif;
  content: "\2013";
}

.italicsFootnote{
  font-size: 10px;
  margin-top: 10px;
}

